import { useEffect } from "react";
import { shortenAddress } from "../../helper/helper";

const ConnectWallet = ({
  connectWallet,
  connectedAccount,
  connecting,
  wallet,
  sendTransaction,
}: {
  connectWallet: () => void;
  connectedAccount: any;
  connecting: boolean;
  wallet: any;
  sendTransaction: () => void;
}) => {
  useEffect(() => {}, []);
  return (
    <>
      <div className="relative w-72">
        <div className="w-full animate-appear-slide-up">
          <div className="flex flex-col items-end justify-end gap-6">
            <h2 className="typography-brand-heading-4-caps w-full pb-4 text-center text-yellow-300">
              CONNECT WALLET
            </h2>
          </div>
          {wallet ? (
            <div className="flex h-max w-72 flex-col items-center">
              <fieldset>
                <legend className="typography-brand-body-l max-w-[256px] text-center text-yellow-100">
                  My Address {shortenAddress(wallet.accounts[0].address)}
                </legend>
                <div className="my-8 flex gap-2 bottom-[10px]"></div>
              </fieldset>
              <div className="transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300">
                <button
                  onClick={sendTransaction}
                  className="disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] bg-yellow-100 focus-visible:bg-white hover:bg-white text-black"
                >
                  {connecting ? "connecting" : "Claim Airdrop"}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex h-max w-72 flex-col items-center">
              <fieldset>
                <legend className="typography-brand-body-l max-w-[256px] text-center text-yellow-100">
                  Enter your invite code to claim your airdrop
                </legend>
                <div className="my-8 flex gap-2 bottom-[10px]"></div>
              </fieldset>
              <div className="transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300">
                <button
                  onClick={connectWallet}
                  className="disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] bg-yellow-100 focus-visible:bg-white hover:bg-white text-black"
                >
                  {connecting
                    ? "connecting"
                    : wallet
                    ? "disconnect"
                    : "connect"}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConnectWallet;
