const Header = ({
  scroll,
  isOpenMenu,
  onSetMenu,
}: {
  scroll: boolean;
  isOpenMenu: boolean;
  onSetMenu: (v: boolean) => void;
}) => {
  const openMenu = () => {
    onSetMenu(true);
  };
  const closeMenu = () => {
    onSetMenu(false);
  };
  return (
    <>
      <div className="sticky top-[14px] z-10 sm:top-[13px]">
        <div className="relative">
          <header className="relative z-[11] flex h-max justify-between px-6 pb-3 pt-9 sm:ml-16 sm:pl-14 sm:pr-0 sm:pt-10 lg:pt-14 sm:mr-0">
            <div className="flex flex-1 items-center justify-between gap-5 overflow-x-hidden">
              {isOpenMenu ? (
                <div className="absolute inset-0 z-[-1] h-screen pb-6 pt-20 backdrop-blur-[12px] backdrop-brightness-50">
                  <div className="w-full h-[2px] translate-y-[-2px] bg-camo-500" />
                  <div className="h-full overflow-auto">
                    {/* <div className="flex h-max flex-col divide-y divide-camo-500 border-b border-camo-500">
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                        href=""
                      >
                        Airdrop
                      </a>
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                        href="/en/leaderboard"
                      >
                        Leaderboard
                      </a>
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                        href="/en/devs"
                      >
                        Devs
                      </a>
                      <a
                        href="https://docs.blast.io/about-blast#why-a-new-l2"
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300 pl-12"
                      >
                        Why Blast
                      </a>
                      <a
                        href="https://docs.blast.io/"
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300 pl-12"
                      >
                        Documentation
                      </a>
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300 pl-12"
                        href="/en/bigbang"
                      >
                        Big Bang Competition
                      </a>
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                        href="/en/about"
                      >
                        About
                      </a>
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300 pl-12"
                        href="/en/about"
                      >
                        Learn More
                      </a>
                      <a
                        href="https://drive.google.com/file/d/1ic_cky5FbcNsoYnEQQxOjxmgRVjQuA9K/view"
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300 pl-12"
                      >
                        Terms
                      </a>
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                        href="/en/bridge"
                      >
                        Bridge
                      </a>
                      <a
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                        href="/en"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          style={{ height: "24px", width: "24px" }}
                        >
                          <use xlinkHref="/icons/library.svg#globe" />
                        </svg>
                        English
                      </a>
                      <a
                        href="https://twitter.com/Blast_L2"
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          style={{ height: "24px", width: "24px" }}
                        >
                          <use xlinkHref="/icons/library.svg#twitter-filled" />
                        </svg>
                        Twitter
                      </a>
                      <a
                        href="https://discord.gg/blast-l2"
                        className="typography-brand-body-l-bold interactive-text flex w-full gap-4 p-6 uppercase tracking-[1.2px] text-camo-300"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          style={{ height: "24px", width: "24px" }}
                        >
                          <use xlinkHref="/icons/library.svg#discord" />
                        </svg>
                        Discord
                      </a>
                    </div> */}
                  </div>
                </div>
              ) : (
                <></>
              )}
              <div className="flex flex-1 items-center justify-between sm:justify-start">
                <a
                  className="flex items-center sm:hidden"
                  style={{ height: 32, width: 136 }}
                  href="/en"
                >
                  <img
                    alt="Blast Logo"
                    loading="lazy"
                    width={136}
                    height={32}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent" }}
                    srcSet="/images/logo-glow.png 1x, /images/logo-glow.png 2x"
                    src="/images/logo-glow.png"
                  />
                </a>
                <a
                  className="hidden items-center sm:flex lg:hidden"
                  style={{ height: 40, width: 170 }}
                  href="/en"
                >
                  <img
                    alt="Blast Logo"
                    loading="lazy"
                    width={170}
                    height={40}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent" }}
                    srcSet="/images/logo-glow.png 1x, /images/logo-glow.png 2x"
                    src="/images/logo-glow.png"
                  />
                </a>
                <a
                  className="hidden items-center lg:flex"
                  style={{ height: 54, width: 256 }}
                  href="/en"
                >
                  <img
                    alt="Blast Logo"
                    loading="lazy"
                    width={256}
                    height={54}
                    decoding="async"
                    data-nimg={1}
                    style={{ color: "transparent" }}
                    srcSet="/images/logo-glow.png 1x, /images/logo-glow.png 2x"
                    src="/images/logo-glow.png"
                  />
                </a>
                <nav className="ml-6 hidden items-center gap-2 xs:flex">
                  {/* <div className="typography-brand-body-l-caps px-2 py-2 sm:px-4">
                    <a
                      className="interactive-text text-camo-300"
                      href="/en/airdrop"
                    >
                      Airdrop
                    </a>
                  </div>
                  <div className="typography-brand-body-l-caps px-2 py-2 sm:px-4">
                    <a
                      className="interactive-text text-camo-300"
                      href="/en/leaderboard"
                    >
                      Leaderboard
                    </a>
                  </div>
                  <div className="typography-brand-body-l-caps px-2 py-2 sm:px-4">
                    <a
                      className="interactive-text text-camo-300"
                      href="/en/devs"
                    >
                      Devs
                    </a>
                  </div>
                  <div className="typography-brand-body-l-caps px-2 py-2 sm:px-4">
                    <a
                      className="interactive-text text-camo-300"
                      href="/en/about"
                    >
                      About
                    </a>
                  </div>
                  <div className="typography-brand-body-l-caps px-2 py-2 sm:px-4">
                    <a
                      className="interactive-text text-camo-300"
                      href="/en/bridge"
                    >
                      Bridge
                    </a>
                  </div>
                  <div>
                    <button
                      aria-haspopup="menu"
                      aria-label="Choose your language"
                      className="text-camo-300 uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed"
                    >
                      <svg
                        style={{ height: 24, width: 24 }}
                        viewBox="0 0 24 24"
                      >
                        <use xlinkHref="/images/library.svg#globe" />
                      </svg>
                    </button>
                  </div> */}
                </nav>
              </div>
              {isOpenMenu ? (
                <div className="block transition-transform duration-500 xs:hidden rotate-90">
                  <button
                    onClick={closeMenu}
                    aria-label="Open Navigation menu"
                    className="text-yellow-100 uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed"
                  >
                    <svg
                      style={{ height: "24px", width: "24px" }}
                      viewBox="0 0 24 24"
                    >
                      <use xlinkHref="/images/library.svg#menu" />
                    </svg>
                  </button>
                </div>
              ) : (
                <div className="block transition-transform duration-500 xs:hidden rotate-0">
                  <button
                    onClick={openMenu}
                    aria-label="Open Navigation menu"
                    className="text-yellow-300 uppercase interactive-text flex items-center gap-1 disabled:cursor-not-allowed"
                  >
                    <svg style={{ height: 24, width: 24 }} viewBox="0 0 24 24">
                      <use xlinkHref="/images/library.svg#menu" />
                    </svg>
                  </button>
                </div>
              )}
              <div className="hidden overflow-x-auto xs:gap-10 sm:flex" />
            </div>
          </header>
          <div className="absolute bottom-0 left-0 right-0 transition-opacity duration-300 opacity-0">
            <div className="w-full h-[2px] bg-camo-500" />
          </div>
          <div
            className={`absolute inset-0 top-[-12px] -z-10 transition-opacity duration-300 opacity-${
              scroll ? 100 : 0
            } backdrop-blur-[12px]`}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
